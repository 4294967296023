import * as React from "react";

import { PageProps, graphql } from "gatsby";
import { TABLET_BREAKPOINT, colors } from "@util/constants";

import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Container } from "@util/standard";
import Hero from "@shared/hero";
import { HeroCarousel } from "@global";
import ImageTextBlockWithCarousel from "@shared/imageTextBlock";
import Layout from "@shared/layout";
import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { returnIDString } from "@util/helper";
import styled from "styled-components";
import { useLocation } from "@reach/router";

interface Props extends PageProps {
  data: Query;
  pageContext: { iban: string; slug: string };
}

const StyledAnchorContainer = styled(Container)<{ length: number }>`
  padding: 20px 0px;
  position: sticky;
  top: 100px;
  background-color: ${colors.primaryBackground};
  z-index: 1;

  .anchor {
    padding: 5px 50px;
    color: ${colors.merlin};
    border-right: 1px solid ${colors.merlin};

    &:nth-last-child(1) {
      border-right: none;
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    overflow: scroll;
  }
`;

const StyledAboutContent = styled.div`
  h4 {
    margin-top: 20px;
  }
`;

const AboutPage = ({ data }: Props) => {
  const sanityAboutPage = data.allSanityAboutPage.nodes[0];

  if (sanityAboutPage == null) {
    return null;
  }

  const { hero, additionalHeros, seo, aboutContent, pageContent } =
    sanityAboutPage;

  const { pathname } = useLocation();

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        {additionalHeros && additionalHeros.length > 0 ? (
          <HeroCarousel
            hero={sanityAboutPage?.hero}
            additionalHeros={sanityAboutPage.additionalHeros}
          />
        ) : (
          <Hero sanityHero={hero} />
        )}

        {aboutContent && (
          <StyledAnchorContainer
            width="100%"
            justifyContent="center"
            length={aboutContent.length}
          >
            {aboutContent.map(section => {
              if (section?.title == null) return;
              const idString = returnIDString(section.title);
              return (
                <AnchorLink
                  className="anchor"
                  to={`${pathname}#${idString}`}
                  title={section?.title}
                >
                  <span>{section?.title}</span>
                </AnchorLink>
              );
            })}
          </StyledAnchorContainer>
        )}

        {pageContent && <ImageTextBlockWithCarousel data={pageContent} />}

        {aboutContent &&
          aboutContent.map(section => {
            if (section == null) return;
            const idString = returnIDString(section?.title);

            return (
              <StyledAboutContent>
                {section.hero && (
                  <Hero id={idString} sanityHero={section.hero} noH1 />
                )}
                {section.content && (
                  <ImageTextBlockWithCarousel data={section.content} />
                )}
              </StyledAboutContent>
            );
          })}
      </Layout>
    </>
  );
};

export default AboutPage;

export const query = graphql`
  query allSanityAbout($iban: String) {
    allSanityAboutPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        additionalHeros {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        pageContent {
          ... on SanityImageTextBlock {
            ...sanityImageTextBlock
          }
          ... on SanityFullWidthContentCarousel {
            ...sanityFullWidthContentCarousel
          }
          ... on SanityFullWidthTextBlock {
            ...sanityFullWidthTextBlock
          }
        }
        aboutContent {
          _key
          _type
          title
          content {
            ... on SanityImageTextBlock {
              ...sanityImageTextBlock
            }
            ... on SanityFullWidthContentCarousel {
              ...sanityFullWidthContentCarousel
            }
            ... on SanityFullWidthTextBlock {
              ...sanityFullWidthTextBlock
            }
          }
          hero {
            ...sanityHero
          }
        }
      }
    }
  }
`;
